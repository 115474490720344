var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{class:{
    'assigned-teacher list-item': true,
    active: _vm.showTeacherInfo,
    substitute: _vm.isSubstitute,
    main: !_vm.isSubstitute
  },on:{"click":_vm.toggleTeacherInfo}},[_c('div',{staticClass:"h6 justify"},[_c('span',[(_vm.isActiveUser)?_c('i',{class:{
          'fa-bookmark error--text': true,
          far: _vm.isSubstitute,
          fas: !_vm.isSubstitute
        }}):_vm._e(),_vm._v(" "),(_vm.noInstructor)?_c('em',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.instructor.name)+" ")]):_c('span',[_vm._v(_vm._s(_vm.instructor.name)+" ")]),(_vm.isActiveUser)?_c('em',{staticClass:"grey--text"},[_vm._v("(you)")]):(_vm.isSubstitute && _vm.instructor.id)?_c('em',{staticClass:"grey--text"},[_vm._v("(Substitute)")]):_vm._e()]),_c('i',{class:{
        'fa-minus-circle error--text spin': _vm.showTeacherInfo,
        'fa-user-circle': _vm.noInstructor || !_vm.showTeacherInfo,
        'grey-light--text': _vm.noInstructor,
        fas: true,
        pulse: !_vm.showTeacherInfo
      }})]),(_vm.showTeacherInfo)?[(!_vm.instructor.desc)?_c('em',{staticClass:"teacher-bio slide-down-fade-in"},[_vm._v("(No user bio)")]):_c('div',{staticClass:"teacher-bio slide-down-fade-in",domProps:{"innerHTML":_vm._s(_vm.instructor.desc)}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }