<template>
  <!-- Action buttons -->
  <aside class="event-actions">
    <!-- All actions -->
    <component
      v-for="(action, i) in actions"
      :key="i"
      :is="action.click ? 'button' : 'router-link'"
      :to="action.to"
      :class="action.class"
      :disabled="action.disabled"
      @click.prevent="action.click"
    >
      <i :class="action.glyph"></i>
      <hr class="divider divider--vertical" />
      <span :class="action.textClass || ''">{{ action.text }}</span>
    </component>

    <!-- Status notification -->
    <span
      v-if="notification"
      class="registration-closed"
      :class="{
        status: isListPath,
        action: !isListPath,
        [`${statusClass} registration-closed`]: true
      }"
    >
      {{ `${isListPath ? "" : type} ${notification}`.trim() }}
    </span>
  </aside>
</template>

<script>
import RegistrationsMixin from "./mixins/registrations.mixin";

export default {
  name: "EventActionButton",

  mixins: [RegistrationsMixin],

  props: { event: Object },

  data: () => ({ actionClass: `action button inline`, loading: false }),

  computed: {
    /* Button generation */
    actions() {
      return [
        // Actions | Variable (i.e. whether or not on List Path)
        ...this.variableActions,

        // Actions | Join/Start Event
        ...this.joinActions,

        // Actions | Global (unregister, mark attendance)
        ...this.globalActions
      ].filter(({ condition }) => condition);
    },

    globalActions() {
      const { actionClass, activeUser, isRegistered } = this;
      const { id, isComplete, isInProgress, hasEnded } = this.event;
      const listAttendees = `List${this.type}Attendees`;

      return [
        {
          // Action | Mark Attendance active
          class: {
            [`${actionClass} outline`]: true,
            disabled: isComplete
          },
          disabled: isComplete,
          text: "Mark Attendance",
          glyph: "fas fa-clipboard-user",
          click: isComplete ? () => {} : null,
          to: isComplete
            ? null
            : { name: listAttendees, params: { [this.idKey]: id } },
          condition:
            !isComplete &&
            (this.assigned || this.isAdminUser) &&
            (isInProgress || hasEnded)
        },

        // Action | Unregister from event
        {
          class: `${actionClass} outline no-shrink`,
          to: null,
          click: () => this.$emit("cancel-registration"),
          text: `I'm not going`,
          textClass: `error--text`,
          glyph: `fas fa-ban error--text`,
          condition: activeUser && isRegistered && !this.entryClosed
        }
      ];
    },

    joinActions() {
      if (this.isListPath || this.hasEnded) return [];

      const { assigned, event } = this;
      const to = { name: "JoinEvent", params: { eventId: event.id } };
      const base = { to, class: this.actionClass };
      const noAction = { ...base, to: null, click: () => {}, disabled: true };

      // (Optional) Show warning if event/class has no zoom link.
      if (!event.zoomLink) {
        return [
          {
            ...noAction,
            glyph: `fas fa-exclamation-triangle accent--text`,
            text: "No zoom link!",
            condition: true
          }
        ];
      }

      const action = assigned ? `Start` : `Join`;

      return [
        // Disabled: "Event start date" for future events
        {
          ...noAction,
          text: `Begins ${event.eventDateTimeToRelative}`,
          glyph: "fas fa-calendar",
          condition: !event.happensToday && this.isRegistered
        },

        // 'Join' or 'Start' button  depending on user role.
        {
          text: `${action} ${event.type}`,
          glyph: `fas fa-door-open`,
          class: `${this.actionClass} success`,
          click: () => this.$emit(`attempting-${action.toLowerCase()}`),
          condition:
            (this.isRegistered || assigned) &&
            (this.canBeJoined || this.inProgress)
        }
      ];
    },

    variableActions() {
      const { activeUser, actionClass, event, isRegistered, isListPath } = this;
      const { productId, isOnlineClass, typePlural } = event;
      const next = `/${typePlural.toLowerCase()}/${event.id}/view`;

      return isListPath
        ? [
            // Action | Login to view event/class
            {
              class: actionClass,
              to: { name: "Login", query: { next } },
              text: "Login to View",
              glyph: "fas fa-lock error-light--text",
              condition: !activeUser && productId
            },

            // Action | View Event
            {
              class: `${actionClass} ellipsis`,
              to: this.viewEventRoute,
              text: `View ${this.type}`,
              glyph: {
                fas: true,
                "fa-presentation": !isRegistered && isOnlineClass,
                "fa-megaphone": !isRegistered && !isOnlineClass,
                "fa-calendar-check success-light--text": isRegistered,
                "grey-white--text": !isRegistered
              },
              condition: !productId || Boolean(activeUser)
            }
          ]
        : [
            // Action | Login to register for event/class
            {
              class: actionClass,
              to: { name: "Login", query: { next: this.$route.path } },
              text: "Login to Register",
              glyph: "fas fa-lock",
              condition: !activeUser
            },

            // Action | Register for Event
            {
              class: actionClass,
              click: this.attemptRegister,
              text: `Register for ${this.type}`,
              textClass: `success--text`,
              glyph: `fas fa-calendar-check success--text`,
              condition: Boolean(activeUser) && this.canRegister
            }
          ];
    },

    notification() {
      if (this.isCanceled) {
        return this.isListPath ? "Canceled" : "was canceled";
      }

      if (this.event.eventStatus === "Completed") {
        return this.isListPath ? "Completed" : "is complete";
      }

      if (this.hasEnded) {
        return this.isListPath ? "Ended" : "has ended";
      }

      if (this.registrationClosed) {
        return this.isListPath ? "Closed" : "registration closed";
      }

      if (this.inProgress) return "In Progress";

      if (this.entryClosed) {
        const { eventStatus } = this.event;
        const status = eventStatus === "Open" ? "In Progress" : eventStatus;
        return this.isListPath ? status : `is ${status}`.toLowerCase();
      }
      return null;
    },

    statusClass() {
      if ((this.event || {}).eventStatus === "Completed") return "success-dark";
      if (this.hasEnded) return "accent";
      const { eventStatus } = this.event;
      return ["Open", "Completed"].includes(eventStatus) ? "success" : "error";
    }
  },

  mounted() {
    this.attachToState();
  },

  methods: {
    attemptRegister() {
      if (this.isListPath) return this.$router.push(this.viewEventRoute);

      this.$emit("attempting-registration");
    },

    editEvent() {
      this.$router.push({
        name: `Edit${this.type}`,
        params: { [this.idKey]: this.event.id }
      });
    }
  }
};
</script>

<style lang="scss" src="./EventActionButton.scss"></style>
