<template>
  <aside
    :class="{
      'assigned-teacher list-item': true,
      active: showTeacherInfo,
      substitute: isSubstitute,
      main: !isSubstitute
    }"
    @click="toggleTeacherInfo"
  >
    <div class="h6 justify">
      <span>
        <i
          :class="{
            'fa-bookmark error--text': true,
            far: isSubstitute,
            fas: !isSubstitute
          }"
          v-if="isActiveUser"
        />&nbsp;
        <em v-if="noInstructor" class="grey--text">
          {{ instructor.name }}&nbsp;
        </em>
        <span v-else>{{ instructor.name }}&nbsp;</span>
        <em class="grey--text" v-if="isActiveUser">(you)</em>
        <em class="grey--text" v-else-if="isSubstitute && instructor.id"
          >(Substitute)</em
        >
      </span>

      <!-- User icon -->
      <i
        :class="{
          'fa-minus-circle error--text spin': showTeacherInfo,
          'fa-user-circle': noInstructor || !showTeacherInfo,
          'grey-light--text': noInstructor,
          fas: true,
          pulse: !showTeacherInfo
        }"
      ></i>
    </div>

    <template v-if="showTeacherInfo">
      <em class="teacher-bio slide-down-fade-in" v-if="!instructor.desc"
        >(No user bio)</em
      >
      <div
        v-else
        class="teacher-bio slide-down-fade-in"
        v-html="instructor.desc"
      />
    </template>
  </aside>
</template>

<script>
import PermissionsMixin from "./mixins/permissions.mixin";
export default {
  name: "TeacherInfo",

  mixins: [PermissionsMixin],

  props: {
    instructor: Object,
    isSubstitute: Boolean
  },

  data: () => ({ showTeacherInfo: false }),

  computed: {
    isActiveUser() {
      if (this.activeUser) {
        return this.instructor.id === this.activeUser.id;
      }

      return false;
    },
    noInstructor() {
      return this.instructor.name === "No Instructor assigned";
    },
    type() {
      return this.isOnlineClass ? "Class" : "Event";
    }
  },

  methods: {
    toggleTeacherInfo() {
      if (!this.noInstructor) {
        return (this.showTeacherInfo = !this.showTeacherInfo);
      }
    }
  }
};
</script>

<style lang="scss">
.assigned-teacher {
  background: #fff;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: $xxlg;
  margin-bottom: $md;
  padding-right: $xs;

  ::selection {
    background-color: transparent;
  }

  &.active {
    height: auto;
    padding-bottom: $sm;
    padding-right: $xs;
  }

  &.main {
    border-color: #000;
  }

  &.substitute {
    border-color: $grey-light;
    border-style: dashed;
  }

  .h6 {
    color: black;
    height: $xxlg;
    line-height: $xxlg;
    margin: 0;
    width: $parent;
  }

  .h6 .fas {
    font-size: $md;

    &.fa-bookmark {
      font-size: $sm;
      color: $error;
    }
  }

  .pulse,
  .spin {
    animation-duration: 280ms;
  }

  .teacher-bio {
    animation-fill-mode: backwards;
    font-size: smaller;
  }
}
</style>
